.page-header-container {
    width:96%;
    height:44px;
    margin: 20px 2% 10px 2%;

    align-items: center;
    justify-content: space-between;
}

.mobile-page-header-container {
    width:96%;
    margin: 20px 2% 10px 2%;
}

.page-header_right-adornment-container {
    align-items: center;
    justify-content: space-evenly;

    height:44px;
}