.page-container {
    width:calc(100vw - 200px);
    overflow-y:auto;
}

.page-container::-webkit-scrollbar {
    width: 12px;          

    min-width: 1px;
    min-height: 1px;
}
.page-container::-webkit-scrollbar-track {
    background: white;     
}
.page-container::-webkit-scrollbar-thumb {
    background-color: #bebebebe; 
    border-radius: 20px;      
    border: 3px solid  white;
}


.content-container {
    width:96%;
    margin: 15px 2%;
    align-items: top;
    justify-content: space-between;
    flex-wrap: wrap;
}

.stack-container {
    overflow-y:scroll;

    width:100%;
}
.stack-container::-webkit-scrollbar {
    width: 12px;          

    min-width: 1px;
    min-height: 1px;
}
.stack-container::-webkit-scrollbar-track {
    background: #eee;     
}
.stack-container::-webkit-scrollbar-thumb {
    background-color: #d8d8d8; 
    border-radius: 20px;      
    border: 3px solid  #eee;
}


.login-body {
    width:100vw;
    height:100vh;

    display:flex;
    justify-items: center;
    align-items: center;

    overflow:hidden;
    position:relative;
}
.login-container {
    background: white;
    border-radius: 3px;

    margin:auto;


    width:320px;

    -webkit-box-shadow: 0 3px 5px #bebebe;
	-moz-box-shadow: 0 3px 5px #bebebe;
	box-shadow: 0 3px 5px #bebebe;
}

.login-logo {
    width:100px;
    height:100px;
    background-image: url(../../assets/full-logo.png);
    background-size: contain;
    background-repeat: no-repeat;
    margin: 30px auto 20px auto;
}


.nl-lang {
    width:28px;
    height:21px;
    background-image: url(../../assets/flags/nl.svg);
    background-size: contain;
    background-repeat: no-repeat;
}

.gb-lang {
    width:28px;
    height:21px;
    background-image: url(../../assets/flags/gb.svg);
    background-size: contain;
    background-repeat: no-repeat;
}