.details-panel {
    background: white;
    border-radius: 8px;

    -webkit-box-shadow: 0 3px 5px #bebebe;
	-moz-box-shadow: 0 3px 5px #bebebe;
	box-shadow: 0 3px 5px #bebebe;

    padding:20px;

    overflow-y:hidden;
}
.details-panel-content {
    overflow-y:auto;
}

.details-panel-content::-webkit-scrollbar {
    width: 12px;          

    min-width: 1px;
    min-height: 1px;
}
.details-panel-content::-webkit-scrollbar-track {
    background: white;     
}
.details-panel-content::-webkit-scrollbar-thumb {
    background-color: #EEEEEE; 
    border-radius: 20px;      
    border: 3px solid  white;
}
.details-panel-content::-webkit-scrollbar-corner {
    background: white;     
}
