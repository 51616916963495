.time-line-ctnr {
  .time-line {
    position: relative;
    margin: 0 0 0px 0;
    padding: 0;
    list-style: none;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: -10px;
      width: 2px;
      background: #bebebe;
      left: 32px;
      border-radius: 2px;
    }
    > .time-label {
      > span {
        font-weight: 600;
        padding: 5px 10px;
        display: inline-block;
        background-color: white;
        color: #000;
        border-radius: 2px;
        font-size: 14px;
      }
    }
    > li {
      position: relative;
      margin-bottom: 10px;
      margin-right: 0;
      > .time-line-item {
        -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
        border-radius: 3px;
        background: #fff;
        color: #444;
        margin-right: 15px;
        position: relative;
        margin-left: 50px;
        > .time-line-header,
        .time {
          font-size: 14px;
          padding: 6px 10px;
        }
        .time {
          font-size: 12px;
          color: #999;
          float: right;
        }
        .time-line-header {
          margin: 0;
          color: #7f7f7f;
          border-bottom: 1px solid #f4f4f4;
          line-height: 1.1;
        }
      }
      > .fa {
        position: absolute;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        color: #666;
        background: #ecf0f1;
        left: 28px;
        top: 9px;
        border: 2px solid #bebebe;
        display: inline-block;
        box-sizing: border-box;
      }
    }
  }
}
