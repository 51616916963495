


.master-panel {
    background-color: #FFFFFF;


    border-radius: 8px;
    max-width: 500px;
    height:264px;

    padding:20px;

    -webkit-box-shadow: 0 3px 5px #bebebe;
	-moz-box-shadow: 0 3px 5px #bebebe;
	box-shadow: 0 3px 5px #bebebe;
}


.import-panel {
    background-color: #FFFFFF;

    border-radius: 8px;
    width:100%;
    min-height:160px;
    max-height:160px;

    padding:20px;

    -webkit-box-shadow: 0 3px 5px #bebebe;
	-moz-box-shadow: 0 3px 5px #bebebe;
	box-shadow: 0 3px 5px #bebebe;
}

.customer-panel {
    background-color: #FFFFFF;

    border-radius: 8px;
    width:100%;
    min-height:240px;
    max-height:240px;

    max-width: 500px;

    padding:20px;

    -webkit-box-shadow: 0 3px 5px #bebebe;
	-moz-box-shadow: 0 3px 5px #bebebe;
	box-shadow: 0 3px 5px #bebebe;
}

.icon-container {
    display:flex;
    justify-content: center;
    align-items: center;

    width:40px;
    height:40px;

    border-radius: 6px;
    

    background-color: #EEEEEE;
}