


.navbar_button-container {
    width:86%;
    height:40px;
    border-radius: 4px;
    cursor:pointer;
    justify-content: left;
    align-items: center;

    margin-bottom: 0px;
    margin-left:auto;
    margin-right:auto;

    transition: 0.2s;

}

.mobile-navbar-container {
    height:60px;
    width:100vw;
    background-color:white;

}


.navbar_button-container:hover {
    background-color: #EEEEEE;
}
.navbar_button-container:active {
    background-color: #32b4ff;
}



.navbar_logo {
    width:80px;
    height:80px;
    background-image: url(../../assets/full-logo.png);
    background-size: contain;
    background-repeat: no-repeat;
}


.navbar_logo-small {
    width:46px;
    height:40px;
    background-image: url(../../assets/full-logo.png);
    background-size: contain;
    background-repeat: no-repeat;
}