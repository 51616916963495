.datagrid-container {
    background: white;
    border-radius: 6px;

    overflow:hidden;

    position: relative;

    height:calc(100vh - 120px);
    width:96%;
    margin: 20px 2% 10px 2%;
    transition:0.2s;


    -webkit-box-shadow: 0 3px 5px #bebebe;
	-moz-box-shadow: 0 3px 5px #bebebe;
	box-shadow: 0 3px 5px #bebebe;
}

.datagrid-tbody_wrapper {
    height:calc(100vh - 250px);
    overflow-y:scroll;
}



.datagrid-tbody_wrapper::-webkit-scrollbar {
    width: 12px;          

    min-width: 1px;
    min-height: 1px;
}
.datagrid-tbody_wrapper::-webkit-scrollbar-track {
    background: white;     
}
.datagrid-tbody_wrapper::-webkit-scrollbar-thumb {
    background-color: #EEEEEE; 
    border-radius: 20px;      
    border: 3px solid  white;
}
.datagrid-tbody_wrapper::-webkit-scrollbar-corner {
    background: white;     
}


.datagrid-center {
    align-items: center;
    justify-items: center;
}
.datagrid-container_wrapper {
    width:98%;
    margin: 10px auto;
}




.datagrid-pagination_wrapper {
    position:absolute;
    height:40px;
    width:98%;
    margin: 10px auto;

    bottom:10px;
    right:10px;


    align-items: center;
    justify-content: space-between;
}

