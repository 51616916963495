
.modal_details-container {

    height:500px;

    overflow-y: auto;
}


.modal_details-container::-webkit-scrollbar {
    width: 12px;          

    min-width: 1px;
    min-height: 1px;
}
.modal_details-container::-webkit-scrollbar-track {
    background: white;     
}
.modal_details-container::-webkit-scrollbar-thumb {
    background-color: #e5e5e5; 
    border-radius: 20px;      
    border: 3px solid  white;
}